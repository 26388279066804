@import './assets/fonts.css';

h1 {
  font-weight: 500;
  line-height: 1em;
}

h2 {
  font-weight: 500;
}

h3 {
  font-weight: 500;
}

button {
  font-family: Jost;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(256, 256, 256, 0.9);
}

button.inset {
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.15), inset -2px -2px 25px rgba(256, 256, 256, 1);
}

button:active {
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.15), inset -2px -2px 25px rgba(256, 256, 256, 1);
}

.card-container {
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  grid-auto-rows: minmax(max-content, 1fr);
}

@media (max-width: 600px){
  .card-container {
    grid-template-columns: 1fr;
  }
}

input {
  font-family: Jost;
  font-size: 14px;
  font-weight: 400;
  border: none;
}
:focus {
  outline: 0;
  outline-color: transparent;
  outline-style: none;
}

a {
  text-decoration: none;
}

p {
  font-size: 14px;
}

body {
  min-height: 100vh;
  font-family: Jost;
  background: #EFEEEE;
}


code {
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
  font-size: 90%;
  padding: 2px 4px;
}